import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "../PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { useNavigate, useLocation } from "react-router-dom";

const getLastDayOfMonth = (year, month) => {
  // Return the first day of the next month
  return new Date(year, month + 1, 1);
};

const getCurrentDate = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Get the first day of the current month (day = 01)
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);

  // Format the date as "YYYY-MM-DD"
  const formattedDate = `${currentYear}-${(currentMonth + 1)
    .toString()
    .padStart(2, "0")}-01`;

  return formattedDate;
};

function RentAmountCalculation() {
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);

  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [client, setclient] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selclientcode, setSelClientCode] = useState("");
  const [selclientname, setselclientname] = useState("");
  const [selprojectname, setselprojectname] = useState("");
  const [listofprojectid, setlistofprojectid] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [Rentdetails, setRentdetails] = useState([]);
  const [Days, setDays] = useState("");
  const [daysDifference, setDaysDifference] = useState([]);
  const [BillNumber, setBillNumber] = useState("");
  const [Startdatesort, setStartdatesort] = useState(getCurrentDate());
  const getClientcode = () => {
    axios
      .get(`${BASEURL}/Fetchingtheclientcode`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setclient(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
      });
    axios
      .get(`${BASEURL}/ListOfProjectIds`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofprojectid(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
      });

    // axios
    //   .get(`${BASEURL}/getnewrentbillNumber`, {
    //     headers: {
    //       "auth-token": authToken,
    //       "session-token": sessiontoken,
    //     },
    //   })
    //   .then((response) => {
    //     setBillNumber(response.data[0].NEXTNO);
    //     // console.log(first);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching List Of Project Idss:", error);
    //   });
  };

  useEffect(() => {
    getClientcode();
  }, []);

  // Function to get the first day of the current month (YYYY-MM-DD format)
  function getFirstDayOfMonth() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    // Set the date to the first day of the current month (day = 01)
    const firstDayOfMonth = new Date(year, month, 1);

    // Format the date as YYYY-MM-DD
    const formattedDate = `${year}-${(month + 1)
      .toString()
      .padStart(2, "0")}-01`;

    return formattedDate;
  }

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;

    if (selectedDate.length === 10) {
      const [year, month] = selectedDate.split("-").map(Number);

      // Create a new Date object for the selected year and month (day = 1 for the first day)
      const firstDayOfMonth = new Date(year, month - 1, 1);

      // Format the date as "YYYY-MM-DD" string
      const formattedFirstDayOfMonth = `${year}-${month
        .toString()
        .padStart(2, "0")}-01`;

      // Set Startdatesort to the formatted first day of the selected month
      setStartdatesort(formattedFirstDayOfMonth);
    }
  };

  function RentCharge() {
    const datePush =
      Startdatesort.slice(8, 10) +
      "/" +
      Startdatesort.slice(5, 7) +
      "/" +
      Startdatesort.slice(0, 4);
    console.log(datePush, "datePush", datePush);
    if (selprojectname && Startdatesort) {
      axios
        .post(
          `${BASEURL}/getunProcessedRents`,
          {
            LEASEENDDATE: datePush,
            PROJECTNAME: selprojectname,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setRentdetails(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching List Of Project Idss:", error);
        });
    }
  }

  useEffect(() => {
    RentCharge();
  }, [selprojectname, Startdatesort]);

  useEffect(() => {
    if (Rentdetails.length > 0) {
      const daysDifferences = Rentdetails.map((item) => {
        const leaseStartDate = new Date(item.LEASESTARTDATE);
        const startMonth = new Date(Startdatesort).getMonth();
        const startYear = new Date(Startdatesort).getFullYear();
        const leaseStartMonth = leaseStartDate.getMonth();
        const leaseStartYear = leaseStartDate.getFullYear();

        // Check if the Startdatesort month and year match with the item.LEASESTARTDATE month and year
        if (startMonth === leaseStartMonth && startYear === leaseStartYear) {
          const year = leaseStartDate.getFullYear();
          const month = leaseStartDate.getMonth();
          const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
          const endOfMonth = new Date(year, month, lastDayOfMonth);
          const differenceInTime = endOfMonth - leaseStartDate;
          return Math.round(differenceInTime / (1000 * 3600 * 24));
        } else {
          return Startdatesort.slice(8, 10);
        }
      });
      console.log(daysDifferences);
      setDaysDifference(daysDifferences);
    }
  }, [Rentdetails, Startdatesort]);

  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const uniqueIdentifier = `${item.CLIENTCODE}-${item.UNITNUMBER}-${item.SUBUNITNO}-${item.LEASESTARTDATE}`;
      const isAlreadySelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.uniqueIdentifier === uniqueIdentifier
      );

      if (isAlreadySelected) {
        return prevSelectedItems.filter(
          (selectedItem) => selectedItem.uniqueIdentifier !== uniqueIdentifier
        );
      } else {
        return [...prevSelectedItems, { ...item, uniqueIdentifier }];
      }
    });
  };
  console.log(selectedItems);

  // function HandleSave() {
  //   if (selectedItems.length === 0) {
  //     alert("Please select at least one item to save.");
  //     return;
  //   }

  //   const updatedRentDetails = Rentdetails.map((item, index) => {
  //     setDays(daysDifference[index]); // Define the 'days' variable here
  //     const totalAmountCharged =
  //       daysDifference[index] !== undefined &&
  //       Startdatesort?.slice(5, 7) === item.LEASESTARTDATE?.slice(5, 7) &&
  //       Startdatesort?.slice(0, 4) === item.LEASESTARTDATE?.slice(0, 4)
  //         ? (
  //             (item.RENTAMOUNT / moment(item.LEASESTARTDATE).daysInMonth()) *
  //             daysDifference[index]
  //           ).toFixed(2)
  //         : item.RENTAMOUNT;
  //   });
  //   console.log(Startdatesort);
  //   const date =
  //     Startdatesort.slice(8, 10) +
  //     "/" +
  //     Startdatesort.slice(5, 7) +
  //     "/" +
  //     Startdatesort.slice(0, 4);
  //   console.log(updatedRentDetails);
  //   console.log(Days);
  //   // const promises = updatedRentDetails.map((item) => {
  //   //   return axios.post(`${BASEURL}/saveRent`, {
  //   //     RENTBILLNO: BillNumber,
  //   //     PROPERTYID: item.PROJECTID,
  //   //     LEASENUMBER: item.LEASENUMBER,
  //   //     CLIENTCODE: item.CLIENTCODE,
  //   //     CLIENTUNITID: item.UNITID,
  //   //     CLIENTSUBUNITID: item.SUBUNITNO,
  //   //     BILLDATE: date,
  //   //     DAYSCOUNTED: item.days, // Use 'days' from the item object
  //   //     STANDARDRENT: item.RENTAMOUNT,
  //   //     RENTCHARGED: item.totalAmountCharged,
  //   //   });
  //   // });

  //   // Promise.all(promises)
  //   //   .then((responses) => {
  //   //     alert("All items saved successfully!");
  //   //     // window.location.reload();
  //   //   })
  //   //   .catch((error) => {
  //   //     console.error("Error posting selected item details:", error);
  //   //   });
  // }

  async function getnewrentbillNumber() {
    try {
      const response = await axios.get(`${BASEURL}/getnewrentbillNumber`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      });
      if (response.status === 200) {
        return response?.data[0]?.NEXTNO;
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(`Error while generating new rent bill Number`, error);
      alert("Failed to generate new rent bill Number");
    }
  }

  async function HandleSave() {
    const confirmed = window.confirm("Are you sure you want to save?");

    if (!confirmed) {
      return;
    }

    if (selectedItems.length === 0) {
      alert("Please select at least one item to save.");
      return;
    }
    const invalidItems = selectedItems.filter((item) => item.RENTAMOUNT === 0);
    if (invalidItems.length > 0) {
      alert(
        `The following clients have a RENTAMOUNT of 0: ${invalidItems
          .map((item) => item.CLIENTNAME)
          .join(", ")}`
      );

      return;
    }

    try {
      const promises = selectedItems.map(async (item, index) => {
        const totalAmountCharged =
          daysDifference[index] !== undefined &&
          Startdatesort?.slice(5, 7) === item.LEASESTARTDATE?.slice(5, 7) &&
          Startdatesort?.slice(0, 4) === item.LEASESTARTDATE?.slice(0, 4)
            ? (
                (item.RENTTOCHARGEAMOUNT /
                  moment(item.LEASESTARTDATE).daysInMonth()) *
                daysDifference[index]
              ).toFixed(2)
            : item.RENTTOCHARGEAMOUNT;

        const date =
          Startdatesort.slice(8, 10) +
          "/" +
          Startdatesort.slice(5, 7) +
          "/" +
          Startdatesort.slice(0, 4);

        console.log({
          RENTBILLNO: BillNumber,
          PROPERTYID: item.PROJECTID,
          LEASENUMBER: item.LEASENUMBER,
          CLIENTCODE: item.CLIENTCODE,
          CLIENTUNITID: item.UNITID,
          CLIENTSUBUNITID: item.SUBUNITNO,
          BILLDATE: date,
          DAYSCOUNTED: daysDifference[index], // Assigning daysDifference[index] to DAYSCOUNTED
          STANDARDRENT: item.RENTAMOUNT,
          RENTCHARGED: item.RENTTOCHARGEAMOUNT,
        });

        const billNo = await getnewrentbillNumber();

        const response = await axios.post(
          `${BASEURL}/saveRent`,
          {
            RENTBILLNO: Number(billNo),
            PROPERTYID: item.PROJECTID,
            LEASENUMBER: item.LEASENUMBER,
            CLIENTCODE: item.CLIENTCODE,
            CLIENTUNITID: item.UNITID,
            CLIENTSUBUNITID: item.SUBUNITID,
            BILLDATE: moment(date).format("DD/MM/YYYY"),
            DAYSCOUNTED: daysDifference[index] ? daysDifference[index] : 0, // Assigning daysDifference[index] to DAYSCOUNTED
            STANDARDRENT: item.RENTAMOUNT,
            RENTCHARGED: item.RENTTOCHARGEAMOUNT,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );

        return response.data;
      });

      const responseData = await Promise.all(promises);
      alert(responseData[0]?.message);
      window.location.reload();
    } catch (error) {
      console.log(error?.response?.data?.message);
      alert(error?.response?.data?.message);
      window.location.reload();
    }
  }

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "4px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const checkAll = () => {
    setSelectedItems(
      Rentdetails.map((item) => ({
        uniqueIdentifier: `${item.CLIENTCODE}-${item.UNITNUMBER}-${item.SUBUNITNO}-${item.LEASESTARTDATE}`,
        ...item,
      }))
    );
  };
  return (
    <div className="elctric__page">
      <MenuNavbar />
      <div className="center-container_1">
        <div className="form-container_1  ">
          <main className="form_info ">
            <header className="header__of__main flex justify-between items-center">
              <h1 className="header__of__page">
                {menu ? menu : "Rent Amount Calculation"}
              </h1>

              <div>
                <button
                  className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
                  onClick={checkAll}
                >
                  Check All
                </button>
                <button
                  className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
                  onClick={HandleSave}
                >
                  Save
                </button>
                <button
                  className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
                  onClick={() => {
                    navigate("/PROCESSEDRENT");
                  }}
                >
                  Processed rent
                </button>
              </div>
            </header>
            <section className="section__newgarage">
              <aside className="aside__new__garage UnitTypeMaster__width">
                {/* <div style={{ width: "202px" }}>
                  <p style={{ width: "140px" }}>
                    <label style={{ width: "140px" }}>BILL&nbsp;NO: </label>
                  </p>
                  <div className="ml-4 text-red-600 font-bold">
                    {BillNumber}
                  </div>
                </div> */}
                <div>
                  <p>
                    <label className="garage_id_">Project Name : </label>
                  </p>
                  <div>
                    <Select
                      styles={customStyless}
                      onChange={(selectedOption) => {
                        if (selectedOption.value === "") {
                          alert("Kindly Supply proper Project Name");
                          setselprojectname("");
                        } else {
                          setselprojectname(selectedOption.value);
                          //   handleProject(selectedOption);
                        }
                      }}
                      value={
                        selprojectname
                          ? {
                              label: selprojectname,
                              value: selprojectname,
                            }
                          : { label: "", value: "" }
                      }
                      options={[
                        { value: "", label: "" },
                        ...listofprojectid.map((item) => ({
                          value: item.PROJECTNAME,
                          label: item.PROJECTNAME,
                        })),
                      ]}
                    />
                  </div>
                </div>

                <div style={{ width: "300px" }}>
                  <p>
                    <label className="garage_id_">Date</label>
                  </p>
                  <div>
                    <input
                      style={{ width: "200px" }}
                      type="date"
                      onChange={handleDateChange}
                      value={Startdatesort}
                    ></input>
                  </div>
                </div>
              </aside>
            </section>
          </main>
        </div>
      </div>
      {isloading ? (
        <Loading />
      ) : (
        <div className="service_detials">
          <table className="table_1 " style={{ fontSize: "12px" }}>
            <thead>
              <tr style={{ textTransform: "uppercase" }}>
                <td></td>
                <td>CLIENT CODE</td>
                <td>CLIENT NAME</td>
                <td>UNIT NUMBER</td>
                <td className="text-right">SUB UNIT NO</td>
                <td>LEASE NO</td>

                <td className="text-right">LEASE START DATE</td>
                <td className="text-right">LEASE END DATE</td>

                <td className="text-right">RENT AMOUNT</td>
                <td className="text-right">RENT CHARGED </td>
              </tr>
            </thead>
            <tbody>
              {Rentdetails.length > 0 ? (
                Rentdetails.map((item, index) => (
                  <tr key={index} style={{ textTransform: "uppercase" }}>
                    <td>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedItems.some(
                            (selectedItem) =>
                              selectedItem.uniqueIdentifier ===
                              `${item.CLIENTCODE}-${item.UNITNUMBER}-${item.SUBUNITNO}-${item.LEASESTARTDATE}`
                          )}
                          onChange={() => handleCheckboxChange(item)}
                        />
                      </label>
                    </td>
                    <td>{item.CLIENTCODE ? item.CLIENTCODE : "NA"}</td>
                    <td>{item.CLIENTNAME ? item.CLIENTNAME : "N/A"}</td>
                    <td style={{ textAlign: "right", paddingRight: "50px" }}>
                      {item.UNITNUMBER ? item.UNITNUMBER : "NA"}
                    </td>
                    <td className="text-right">
                      {item.SUBUNITNO ? item.SUBUNITNO : "NA"}
                    </td>
                    <td className="text-right">
                      {item.LEASENUMBER ? item.LEASENUMBER : "NA"}
                    </td>

                    <td className="text-right">
                      {item.LEASESTARTDATE
                        ? moment(item.LEASESTARTDATE).format("DD/MM/YYYY")
                        : "NA"}
                    </td>
                    <td className="text-right">
                      {item.LEASEENDDATE
                        ? moment(item.LEASEENDDATE).format("DD/MM/YYYY")
                        : "NA"}
                    </td>

                    <td
                      // className="text-right"
                      className={`uppercase text-right ${
                        item.RENTAMOUNT === 0 ? "bg-red-200" : ""
                      }`}
                      title={item.RENTAMOUNT === 0 ? "Rent Amount is zero" : ""}
                    >
                      {item?.RENTAMOUNT?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    {/* <td className="text-right">
                      {daysDifference[index] !== undefined &&
                      Startdatesort?.slice(5, 7) ===
                        item.LEASESTARTDATE?.slice(5, 7) &&
                      Startdatesort?.slice(0, 4) ===
                        item.LEASESTARTDATE?.slice(0, 4)
                        ? (
                            (item.RENTTOCHARGEAMOUNT /
                              moment(item.LEASESTARTDATE).daysInMonth()) *
                            daysDifference[index]
                          ).toFixed(2)
                        : item?.RENTTOCHARGEAMOUNT?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                      {/* Displaying Rent Amount */}
                    {/* </td> */}
                    <td
                      // className="text-right"
                      className={`uppercase text-right ${
                        item.RENTTOCHARGEAMOUNT === 0 ? "bg-red-200" : ""
                      }`}
                      title={
                        item.RENTTOCHARGEAMOUNT === 0
                          ? "Rent to Charge is zero"
                          : ""
                      }
                    >
                      {item?.RENTTOCHARGEAMOUNT?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    No data for selected date
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default RentAmountCalculation;
