import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import "../PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import Select from "react-select"; // Import react-select
import { useNavigate, useLocation } from "react-router-dom";
const RentSaved = () => {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [RentSaved, setRentSaved] = useState([]);
  const [isloading, setisloading] = useState(true);

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth?.toISOString()?.split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );

  useEffect(() => {
    masterData();
  }, []);

  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };

  const filteredData = RentSaved?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    if (searchData.length === 0 && !fromDateFilter && !toDateFilter) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        ["CLIENTCODE"],
        ["CLIENTNAME"],
        ["PROJECTNAME"],
        ["LEASENUMBER"],
        ["UNITID"],
        ["SUBUNITID"],
        ["BILLDATE"],
        ["DAYSCOUNTED"],
        ["STANDARDRENT"],
        ["RENTCHARGES"],
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    // const Date = item.BILLDATE;
    if (searchData.length === 0 && !fromDateFilter && !toDateFilter) {
      return true;
    }
    // const dateMatch =
    //   (!fromDateFilter || Date >= fromDateFilter) &&
    //   (!toDateFilter || Date <= toDateFilter);

    const billDate = new Date(item.BILLDATE).getTime();
    const fromDate = fromDateFilter ? new Date(fromDateFilter).getTime() : null;
    const toDate = toDateFilter ? new Date(toDateFilter).getTime() : null;

    const dateMatch =
      (!fromDate || billDate >= fromDate) && (!toDate || billDate <= toDate);

    return textMatch && dateMatch;
  });

  function masterData() {
    setisloading(true);
    axios
      .get(`${BASEURL}/getSavedRent`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        setRentSaved(response.data);
        console.log(response.data);
      });
  }

  if (isloading) {
    return <Loading />;
  }
  const totalRentCharges = filteredData.reduce((total, item) => {
    return total + (item?.RENTCHARGES || 0);
  }, 0);

  return (
    <div>
      <div className="toHIde__when__Print">
        <MenuNavbar />
        <div>
          <header className="header__of__main lg:flex justify-between px-3 items-center">
            <h1 className="header__of__page">
              {menu ? menu : "Processed Rents"}
            </h1>
            <button
              className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
              onClick={() => {
                navigate("/RentAmountCalculation");
              }}
            >
              Back
            </button>
          </header>

          <article>
            <div className="flex gap-3 relative mx-[30px] my-2"></div>
            <div className="lg:flex items-center justify-between mx-[30px]">
              <div className="flex">
                <p className="lg:mx-2 text-[16px]">Search</p>
                <input
                  className="w-[300px] lg:mr-[50px] h-[30px]"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              <section className="lg:flex gap-3 items-center  lg:px-[20px] text-[16px]">
                <div className="lg:ml-[10px] md:ml-0 flex items-center ">
                  <p className="w-[60px] lg:w-auto py-1">From&nbsp;</p>
                  <input
                    type="date"
                    value={fromDateFilter}
                    onChange={handleFromDateFilterChange}
                  />
                </div>
                <div className="lf:ml-[10px] md:ml-0 flex items-center ">
                  <p className="w-[60px] lg:w-auto py-1">To&nbsp;</p>
                  <input
                    type="date"
                    value={toDateFilter}
                    onChange={handleToDateFilterChange}
                  />
                </div>
              </section>
            </div>
            <div className="service_detials ">
              <table className="table_1">
                <thead>
                  <tr className="text-right">
                    {/* <td>BILL NO</td> */}
                    <td>CLIENT CODE</td>
                    <td className="text-left">CLIENT NAME</td>
                    <td className="text-left">PROJECT NAME</td>
                    <td>LEASE NUMBER</td>

                    <td>UNIT NUMBER</td>
                    <td>SUB UNIT ID</td>
                    <td>BILL DATE</td>
                    <td>DAYS COUNTED</td>
                    <td>STANDARD RENT</td>
                    <td>RENT CHARGES</td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredData) && filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                      <tr key={index} className="table-row">
                        <td className="text-right">
                          {item?.CLIENTCODE || "N/A"}
                        </td>
                        <td className="text-left">
                          {item?.CLIENTNAME || "N/A"}
                        </td>
                        {/* <td className="text-right">{ item?.BILLNO || "N/A"}</td> */}
                        <td className="text-left">
                          {item?.PROJECTNAME || "N/A"}
                        </td>
                        <td className="text-right">
                          {item?.LEASENUMBER || "N/A"}
                        </td>

                        <td className="text-right">{item?.UNITNO || "N/A"}</td>
                        <td className="text-right">
                          {item?.SUBUNITID || "N/A"}
                        </td>
                        <td className="text-right">
                          {item?.BILLDATE?.slice(8, 10) +
                            "/" +
                            item?.BILLDATE?.slice(5, 7) +
                            "/" +
                            item?.BILLDATE?.slice(0, 4) || "N/A"}
                        </td>
                        <td className="text-right">
                          {item?.DAYSCOUNTED || "0"}
                        </td>
                        <td className="text-right">
                          {item?.STANDARDRENT.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) || "0"}
                        </td>
                        <td className="text-right">
                          {item?.RENTCHARGES.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) || "0"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10">No data available</td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="10" className="text-right font-bold mr-[2px]">
                      Total Rent Charges :
                      <span className="text-red-600 ml-[8px]">
                        {totalRentCharges.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default RentSaved;
